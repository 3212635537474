import React from 'react'

import PageLink from '@/link.js'
import Image from '@/image.js'

export default ({ product }) => {
  return (
    <div className='x product__card'>
      <PageLink to={`/products/${product.slug.current}`}>
        <Image imageId={product.imageId} alt={product.title} />
        <h5 className='mono m0 pt1'>${product.defaultProductVariant.price}</h5>
        <p className='m0 p0 mt05'>{product.title}</p>
      </PageLink>
    </div>
  )
}