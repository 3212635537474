import React from 'react'
import cx from 'classnames'

import Footer from '@/footer.js'
import META from '@/meta'
import ProductCard from '@/products/productCard.js'
import ProductCarousel from '@/products/productCarousel.js'
import { QueryCollection } from '../utls/queryCollection.js'

const Shop = ({ transitionStatus, entry, exit }) => {
  const data = QueryCollection()
  return (
    <div className={cx('animate__page', transitionStatus)}>
      <META data={data} />
      <div className='nav__push' />
      <div className='container--xl ma x p1'>
        <h1>Shop</h1>
        <ProductCarousel data={data.featuredProducts.product} />
      </div>
      <div className='p1 container--xl px15 ma x fw'>
        <div className='shop__all'>
          <h2 className='mt0 pt0 mb0'>All Products</h2>
        </div>
        <div className='f jcb fw aic collection__grid'>
          {data.products.map(product => (
            <ProductCard key={product._key} product={product} />
          ))}
          <div className='x' />
          <div className='x' />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Shop
