import { useStaticQuery, graphql } from 'gatsby'

export const QueryCollection = () => {
  const query = useStaticQuery(
    graphql`
      {
        sanityCollection {
          id
          internal {
            type
            content
            contentDigest
            owner
          }
        }
      }
    `
  )
  return JSON.parse(query.sanityCollection.internal.content)
}
