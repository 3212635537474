import React from 'react'

import Image from '@/image.js'
import PageLink from '@/link.js'
import { Arrow } from '@/svgs.js'

export default class extends React.Component {
  constructor () {
    super()
    this.state = {
      activeSlide: 1
    }
    this.slideshow = React.createRef()
    this.leftArrow = React.createRef()
    this.rightArrow = React.createRef()
  }
  componentDidMount () {
    const Flickity = require('flickity')
    const flky = new Flickity(this.slideshow.current, {
      contain: true,
      wrapAround: true,
      pageDots: false,
      bgLazyLoad: 2,
      cellAlign: 'center',
      prevNextButtons: false
    })
    this.leftArrow.current.addEventListener('click', () => flky.previous())
    this.rightArrow.current.addEventListener('click', () => flky.next())

    flky.on('select', (index) => {
      this.setState({
        activeSlide: index + 1
      })
    })
    setTimeout(() => {
      flky.resize()
    }, 100)
  }
  render() {
    return (
      <div className='x rel cw carousel__wrapper' >
        <div ref={this.leftArrow} className='slide__controls slide__controls-left abs z1 left'>
          <Arrow />
        </div>
        <div ref={this.rightArrow} className='slide__controls slide__controls-right abs z1 right'>
          <Arrow />
        </div>
        <div className='abs z2 right bottom slide__counter p1 h3'>{this.state.activeSlide}/{this.props.data.length}</div>
        <div ref={this.slideshow} className='product__slideshow'>
          {this.props.data.map(product => (
            <div className='x rel' key={product._rev}>
              <PageLink className='abs z2 x y' to={`/products/${product.slug.current}`} />
              <div className='abs x y f jcc z1 aie'>
                <div className='x hero__carousel-text product__slideshow-text p1'>
                  <h5 className='mono m0 pt1'>${product.defaultProductVariant.price}</h5>
                  <h3 className='m0 p0'>{product.title}</h3>
                </div>
              </div>
              <Image className='x abs y' imageId={product.slideshowImageId ? product.slideshowImageId : product.imageId} alt={product.title} />
              <Image className='product__slideshow-hidden' imageId={this.props.data[1].slideshowImageId ? this.props.data[1].slideshowImageId : this.props.data[1].imageId} />
            </div>
          ))}
        </div>
      </div>
    )
  }
}